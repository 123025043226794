import UserLoggedService from '@/services/resources/UserLoggedService'
import UserResourcesService from '@/services/resources/UserResourcesService';
import TagService from '@/services/resources/TagService';
import router from '@/router/index';
import getters from '@/store/modules/user/getters';

const serviceLogged = UserLoggedService.build();
const serviceResources = UserResourcesService.build();
const serviceTag = TagService.build();

export default {
    userRequest: async({ commit, dispatch }) => {

        // Load user data
        const user = await serviceLogged.get('');
        commit('userSuccess', user);

        // Load user resources data
        const resources = await serviceResources.get('');
        commit('userResourcesSuccess', resources);

        // Load all tags
        const tags = await serviceTag.get('');
        commit('userTagSuccess', tags);

        // Redirect to home page
        dispatch('redirectUserToHome');

    },
    redirectUserToHome: (context) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const params2 = urlParams.get("rota");

        router.push('/'+params2)
    }
}