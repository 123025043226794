<template>
  <div class="container-fluid p-0" id="app" >
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'app'
}
</script>

<style lang="scss">
@import './assets/scss/main.scss';


</style>
