<template>
  <div class="slidebar">
    <a @click="getUrlHome" class="logox">
      <img
        @click="getUrlHome"
        class="logo"
        src="@/../public/logo.png"
        width="35"
      />
    </a>
    <div class="btns-slidebar">
      <router-link
        v-for="link of links"
        :to="link.to"
        :key="link.title"
        class="link-url remove-underline "
        :class="link.name"
      >
        <div class="block-slidebar flex">
          <img :src="link.icon" :alt="link.title" />
          <p class="text-slidebar opacityAnime" >{{ link.title }}</p>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import RedirectService from "@/services/resources/RedirectService";
const service = RedirectService.build();

export default {
  name: "custom-slidebar",
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  watch: {
    $route: function () {
      this.updateClassActived()
    },
  },
 
  methods: {
    getUrlHome() {
      service.create({}).then((resp) => {
        let url2 = "https://" + resp.domain + "/gadmin/?rdrk=" + resp.ck;
        window.location.href = url2;
      });
    },
    updateClassActived() {
      const nameRoute = this.$route.name;
      const classActived = 'router-link-exact-active';
      let el = this.$children.find((item)=>{
        if(
          item.$el.classList.contains(classActived) ||
          item.$el.classList.contains(nameRoute)
        ){ 
          return item.$el
        }
      })
      if(el){
        setTimeout(()=>(el.$el.classList.add(classActived)), 30)
      }
      
    }
  },
  mounted: function(){
    this.updateClassActived()
  }
};
</script>

<style lang="scss">
.block-slidebar img {
  width: 20px;
  height: 20px;
}

button#notification_button {
  z-index: 9999999999999999999;
  left: 23px;
}

.close-icon {
  margin-left: 298px !important;
}

.readall-icon {
  margin-left: 238px !important;
}
</style>
<style lang="scss" scoped>


.router-link-exact-active{
  width: 4px;
  height: 68px;
  left: 0px;
  top: 235.55px;
  background: linear-gradient(180deg, #00E4A0 0%, rgba(0, 228, 160, 0) 100%);
  border-radius: 0px 5px 5px 0px;
}


@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.slidebar {
  position: fixed;
  width: 98px;
  background: white;
  box-shadow: 0px 4px 4px rgba(237, 237, 240, 0.5);
  height: 100%;
  border-right: solid 1px #e3e2e5;
  z-index: 999;
  -webkit-transition: 0.5s ease-in;
  -moz-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
  transition: 0.5s ease-in;
}

.logox {
  cursor: pointer;
}

.pisc {
  -webkit-animation: pisc 2s linear infinite;
  -moz-animation: pisc 2s linear infinite;
  -ms-animation: pisc 2s linear infinite;
  -o-animation: pisc 2s linear infinite;
  animation: pisc 2s linear infinite;
}

@keyframes pisc {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.25;
  }
}

.close-icon {
  position: absolute;
  margin-left: 296px;
  color: #7f7f7f;
  cursor: pointer;
  width: 20px;
  height: 20px;
  text-align: center;
}

.altura {
  height: 20px;
  margin-top: 39px;
  margin-bottom: 10px;
}

.indicadores-logados {
  display: none;
  margin-left: 28px;
}

.svg-blogs {
  position: relative;
  top: -5px;
  margin-right: 10px;
}

.slidebar:hover .indicadores-logados {
  display: block;
  -moz-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
  transition: 0.5s ease-in;
}

.chamablog {
  cursor: pointer;
}

.slidebar:hover {
  position: fixed;
  width: 304px;
  background: white;
  box-shadow: 0px 4px 4px rgba(237, 237, 240, 0.5);
  height: 100%;
  -webkit-transition: 0.5s ease-in;
  -moz-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
  transition: 0.5s ease-in;
}

.slidebar:hover .text-slidebar {
  display: flex;
  -moz-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
  transition: 0.5s ease-in;
}

.slidebar:hover .text-indica {
  -moz-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
  transition: 0.5s ease-in;
}

.slidebar:hover .block-slidebar {
  width: 250px;
}

.flex {
  display: flex;
}

.block-slidebar:hover {
  background: #ffffff00;
  filter: invert(0.4) sepia(1) saturate(3) hue-rotate(106.2deg) brightness(1);
}

.block-slidebar:hover .text-slidebar {
  color: #333333;
}

.block-slidebar {
  margin-left: 39px;
  width: 55px;
  height: 68px;
  background: #fff;
  border-radius: 10px;
  align-items: center;
}

.text-slidebar {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  display: none;
  margin-left: 20px;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 20px;
}

.logo {
  margin-left: 33px;
  display: block;
  margin-top: 25px;
}

.btns-slidebar {
  display: grid;
  margin-top: 10vh;
}

.remove-underline {
  text-decoration: none;
}

.text-indica {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #333;
}

.opacityAnime {
  animation: animationFrames ease-in 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: animationFrames ease-in 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: animationFrames ease-in 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: animationFrames ease-in 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: animationFrames ease-in 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
}

@keyframes animationFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes animationFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes animationFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes animationFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes animationFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>