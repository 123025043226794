<template>
<b-modal 
    id="modalSelectBlog" 
    size="md"
    hide-footer>
    <div class="flex">
        <div class="pointer"></div>
        <p class="title-modal">Qual projeto deseja acessar ?</p>
    </div>
    <ul class="blog-list">
        <li v-for="item in blogs" :key="item.site_id" @click="select(item)"> {{ item.blogName }}</li>
    </ul>

</b-modal>
</template>
<script>
export default {
    props:{
        blogs: {
            type: Array,
            default: []
        }
    },
    
    methods:{
        select(item){
            this.$emit('selected', item);
            this.$bvModal.hide('modalSelectBlog');
        }
    }

}
</script>

<style lang="scss">
#modalSelectBlog .modal-header {
    border: none !important;
}
</style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
.flex{
    display: flex;
}

.pointer{
    width: 9px;
    height: 9px;
    background: #00E4A0;
    border-radius: 1px;
    margin-top: 8px;
    margin-right: 15px;
}
.title-modal{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
}
.blog-list{
    list-style: none;
    padding: 0;
    margin: 0;

    li{
        display: block;
        padding: 10px;
        background: #fefefe;
        cursor: pointer;
        border: 1px #ddd solid;
        border-radius: 2px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;

        &:hover{
            background: rgba(0, 228, 160, 0.05);
            border: 1px solid #00E4A0;
        }
    }
}
</style>