<template>
  <div>
      <b-modal
        :id="idModal"
        centered
        content-class="shadow"
        
        hide-footer
        hide-header
        :size="size"
        :title="title"
        @show="modalShow"
        @hidden="modalHide"
      >
        
        <slot />
      </b-modal>
    </div>
</template>
<script>
export default {
  props: {
    title: String,
    idModal:{
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  methods: {
    modalShow(event) {
      this.$emit('modalShow', event)
    },
    modalHide(event) {
      this.$emit('modalHide', event)
    }
  },
}
</script>
<style >
  

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out !important;
  -webkit-transition: -webkit-transform 0.3s ease-out !important;
  transition: transform 10s ease-out !important;
  transition: transform 10s ease-out, -webkit-transform 0.3s ease-out !important;
  -webkit-transform: translate(0, -50px) !important;
  transform: translate(0, -50px) !important;
}

.modal.fade.show {
  animation: slide 0.3s;
}

.modal.fade{
  animation: slide-close 0.3s;
}

@keyframes slide-close {
  0% {
    transform: translateY(0);
  }100%{
    transform: translateY(-50%);
  }
}

@keyframes slide {
  0% {
    transform: translateY(-50%);
  }
}

</style>