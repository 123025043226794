import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VeeValidate from 'vee-validate';
import { Validator } from 'vee-validate';
import pt_BR from 'vee-validate/dist/locale/pt_BR';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueTheMask from 'vue-the-mask'
import components from './components/index'
import infiniteScroll from 'vue-infinite-scroll'
import VueLoading from 'vue-loading-overlay'
import Snackbar from 'node-snackbar'
import VueObserveVisibility from 'vue-observe-visibility'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


Vue.use(VueObserveVisibility)
Vue.use(VueLoading);
Vue.use(Snackbar);
Vue.use(infiniteScroll);
Vue.use(VueTheMask);
Vue.use(require('vue-moment'));
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
library.add(fas);
library.add(fab);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
Validator.localize('pt_BR', pt_BR);
Vue.component('font-awesome-icon', FontAwesomeIcon);


for (let componentKey in components) {
    Vue.component(componentKey, components[componentKey])
}

router.afterEach((to, from) => {
    window.document.dispatchEvent(new CustomEvent('vueRouteChangeSuccess'));
})


Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')