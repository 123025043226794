import Rest from '@/services/Rest';

/**
 * @typedef {UserResourcesService}
 */
export default class UserResourcesService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'recurso'

    // Using this because dev server dosen't have this endpoint
    static base = 'https://api.innovaweb.com.br/'
}