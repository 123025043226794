<template>
    <div class="content-alert">
    <b-alert
      :show="getTimeDimiss"
      dismissible
      fade
      :variant="bgAlert?bgAlert:'danger'"
      @dismiss-count-down="countDownChanged"
    >
      {{ text }}
    </b-alert>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props:{
    text: {
      type: String,
      require: true
    },
    bgAlert: {
      type: String,
      default: 'danger'
    }
  },
    data() {
      return {
        dismissSecs: 5,
        dismissCountDown: this.text ? 5 : 0,
        showDismissibleAlert: false
      }
    },
    computed: {
      getTimeDimiss: function (){
          return this.text ? 5 : 0;
      }
    },
    methods: {
      ...mapMutations(['setMessage']),

      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
        if(!this.dismissCountDown){
          this.setMessage({message:'', bg:''})
        }
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }
    }
    }
</script>

<style scoped>

.content-alert{
  position: fixed;
  padding: 15px;
  font-weight: 900;
  top:0;
  right: 5px;
  z-index: 9999;
  position: fixed;
}
.alert{
  color: #333333;
  font-weight: 800;
}
    
</style>