<template>
    
    <b-button variant="primary" @click="btnClick">

        <slot name="content">
        </slot>
    </b-button>



</template>
<script>
export default {

    props:{
        title:{
            type: Array,
            required: true
        }
    },

    data(){
        return{
            
        }
    },

    methods:{
        btnClick(){
            this.$emit('clicked', 'asd');
        }
    }
    
}
</script>

