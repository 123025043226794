import store from '@/store/index'

function isAuthenticated(to, from, next) {
    if (store.getters.isAuthenticated) {
    return next()
  }

  return next('/')
}

const authentication = isAuthenticated

export default authentication
