import Cookies from 'js-cookie';
import router from '@/router/index'

export default {

    loginSuccess: (state, resp) => {
        state.blogs = resp.site;
        Cookies.set('blogs', JSON.stringify(resp.site), { expires: 365 });
    },

    userSuccess: (state, resp) => {
        state.user = resp.user;
        state.blog = resp.blog;
        Cookies.set('user', JSON.stringify(resp.user), { expires: 365 });
        Cookies.set('blog', JSON.stringify(resp.blog), { expires: 365 });
    },

    userResourcesSuccess: (state, resp) => {
        state.resources = resp;
        Cookies.set('resources', JSON.stringify(resp), { expires: 365 });
    },

    userTagSuccess: (state, resp) => {
        state.tags = resp;
        localStorage.setItem('tags', JSON.stringify(resp));
    },

    userLogout: (state) => {
        state.user = null;
        state.blog = null;
        state.blogs = [];
        state.tags = [];
        state.resources = {};
        Cookies.remove('user');
        Cookies.remove('blog');
        Cookies.remove('blogs');
        localStorage.removeItem('tags');
        Cookies.remove('resources');
        router.push('/');
    },

    userLogoutRdrk: (state) => {
        state.user = null;
        state.blog = null;
        state.blogs = [];
        state.tags = [];
        state.resources = {};
        Cookies.remove('user');
        Cookies.remove('blog');
        Cookies.remove('blogs');
        localStorage.removeItem('tags');
        Cookies.remove('resources');
    }

}