import Cookies from 'js-cookie';
import AuthService from '@/services/resources/AuthService';
import { faStripeS } from '@fortawesome/free-brands-svg-icons';

export default {

    //fazer login do OAuth 2
    loginRequest(context, payload) {
        var service = AuthService.build();
        return new Promise((resolve, reject) => {

            service.login(payload.username, payload.password)
                .then((resp) => {

                    if (resp.msg === "sucesso") {
                        context.commit('loginSuccess', resp);
                        resolve(resp);
                    } else {
                        Cookies.remove('access_token');
                        reject(err);
                    }
                })
                .catch((err) => {
                    Cookies.remove('access_token');
                    reject(err);
                })
        })
    },
    blogSelected(context, payload) {
        Cookies.set('access_token', payload.auth, { expires: 365 });
        context.commit('authSuccess', payload.auth);
        context.dispatch('userRequest');
    },

    logoutRequest: ({ commit, dispatch }) => {

        // Since the logout process not need to make a request
        // just remove the cookies and redirect
        Cookies.remove('access_token');
        commit('authLogout');
        commit('userLogout');

    },

    logoutToRdrkRequest: ({ commit, dispatch }) => {
        Cookies.remove('access_token');
        commit('authLogout');
        commit('userLogoutRdrk');

    }
}