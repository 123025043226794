
export default  {
  setMessage(state, {message, bg}){
    state.messageAlert = message
    state.bgAlert = bg
  },
  loading(state, payload){
    state.loading = payload
  },
  alertError(state, payload){
    let messages = "";
    for (const error in payload.error) {
      messages += payload.error[error][0] + "\n";
    }
    alert(messages);
  }
}