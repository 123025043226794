import Api from '@/services/API';


/**
 * @typedef {AuthService}
 */

export default class Auth extends Api {

    /**
   * @type {String}
   */
    static base =  'https://gpages.com.br'
    // testespark.gpages.com.br


/**
   * @type {String}
   */
  static resource = '/gadmin/login/valida.php'

    /**
       * @param {String} resource
       * @param {Object} options
       * @param {Object} http
       */
    constructor(resource, options = {}, http = null) {
        super(Auth.normalize(Auth.base, resource), options, http);
            }

    /**
   * @return {this}
   */
    static build() {
        return new this(this.resource);
    }

    login(username,password) {

        let data = {
            email: username,
            senha: password,
        }

        return this.post('',data);
    }

    logout() {
        return this.post();
    }
}
