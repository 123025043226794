
export default {
  getMessage: (state) =>{
    return state.messageAlert;
  },
  getBgMessage: (state) => {
    return state.bgAlert
  },
  getLoading:(state) => {
    return state.loading
  }
}