import Cookies from 'js-cookie';

import actions from '@/store/modules/user/actions'
import getters from '@/store/modules/user/getters';
import mutations from '@/store/modules/user/mutations'


const state = {
   user: (Cookies.get('user')) ? JSON.parse(Cookies.get('user')) : {},
   blog: (Cookies.get('blog')) ? JSON.parse(Cookies.get('blog')) : {},
   blogs: (Cookies.get('blogs')) ? JSON.parse(Cookies.get('blogs')) : [],
   tags: (localStorage.getItem('tags')) ? JSON.parse(localStorage.getItem('tags')) : [], 
   resources: (Cookies.get('resources')) ? JSON.parse(Cookies.get('resources')) : {},
}


export {
    state,
    getters,
    actions,
    mutations,
}
