import middleware from '@/router/middleware'
import authentication from '@/router/middleware/authentication'
import unauthentication from '@/router/middleware/unauthentication'



export default [{
        path: '/',
        name: 'Login',
        component: () =>
            import ('../../views/Login.vue'),
        beforeEnter: middleware([unauthentication]),
    },
    {
        path: '/home',
        name: 'Home',
        component: () =>
            import ('../../views/Home.vue'),
        beforeEnter: middleware([authentication]),
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: () =>
            import ('../../views/Welcome.vue'),
        beforeEnter: middleware([unauthentication]),
    },
    {
        path: '/broadcast',
        name: 'Broadcast',
        component: () =>
            import ('../../views/Broadcast.vue'),
        beforeEnter: middleware([authentication]),
    },
    {
        path: '/broadcast/lista',
        name: 'Lista de Broadcasts',
        component: () =>
            import ('../../views/BroadcastListagem.vue'),
        beforeEnter: middleware([authentication]),
    },
    {
        path: '/broadcast/lista/eventos',
        name: 'Lista de Eventos',
        component: () =>
            import ('../../views/BroadcastListagemEventos.vue'),
        beforeEnter: middleware([authentication]),
    },
    {
        path: '/broadcast/wizard',
        name: 'Criar Broadcast',
        component: () =>
            import ('../../views/BroadcastWizard.vue'),
        beforeEnter: middleware([authentication]),
    },
    {
        path: '/lead-ads',
        name: 'Lista-de-fanpages',
        component: () => import('../../views/LeadAd.vue'),
        beforeEnter: middleware([authentication]),
    },
    {
        path: '/lead-ads/fanpage/:id(\\d+)',
        component: () => import('../../views/LeadAdFanpage.vue'),
        name:'Fanpage'
    },
    {
        path: '/lead-ads/:idFanpage(\\d+)?/leadgen/:idIntegration(\\d+)?',
        component: () => import('../../views/LeadAdHistoric.vue'),
        name:'Historic-integration',
    },
    {
        path: '/pixel',
        component: () => import('../../views/Pixel.vue'),
        name:'Pixel',
        beforeEnter: middleware([authentication]),
    },
 
    
]